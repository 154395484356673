import React, { useCallback } from "react"
import { colors } from "../../utils"

export function Logo({ onClick = null }) {
  const handleClick = useCallback(() => {
    if (onClick && typeof onClick === "function") onClick()
  }, [onClick])
  return (
    <div
      style={{
        marginTop: 70,
        fontWeight: 500,
        display: "flex",
        alignItems: "center",
        fontSize: 24,
        fontFamily: "Poppins",
        cursor: onClick !== null ? "pointer" : "auto",
      }}
      onClick={handleClick}
    >
      <span style={{ color: colors.basic }}>Powr</span>
      <span style={{ color: colors.grey }}>Kor</span>
      <svg
        style={{
          position: "relative",
          bottom: "12px",
        }}
        xmlns="http://www.w3.org/2000/svg"
        width="110.043"
        height="114.213"
        viewBox="0 0 110.043 114.213"
      >
        <g
          id="Group_289"
          data-name="Group 289"
          transform="translate(-249.479 -76.894)"
        >
          <g
            id="Polygon_1"
            data-name="Polygon 1"
            transform="translate(306.479 144.894) rotate(30)"
            fill="#f8f9fa"
          >
            <path
              d="M 25.44214248657227 31 L 11.55785179138184 31 C 10.49020195007324 31 9.495012283325195 30.42580986022949 8.960641860961914 29.50149917602539 L 2.023141860961914 17.50151062011719 C 1.487711906433105 16.57534027099609 1.487711906433105 15.42465019226074 2.023141860961914 14.49849033355713 L 8.960641860961914 2.49849009513855 C 9.495012283325195 1.574190020561218 10.49020195007324 1 11.55785179138184 1 L 25.44214248657227 1 C 26.50979232788086 1 27.50498199462891 1.574190020561218 28.03934097290039 2.49849009513855 L 34.97686004638672 14.49849033355713 C 35.51229095458984 15.42465972900391 35.51229095458984 16.57534980773926 34.97686004638672 17.50151062011719 L 28.03935241699219 29.50151062011719 C 27.50498199462891 30.42580986022949 26.50979232788086 31 25.44214248657227 31 Z"
              stroke="none"
            />
            <path
              d="M 11.55785179138184 2 C 10.84609222412109 2 10.18262100219727 2.38279914855957 9.826381683349609 2.999000549316406 L 2.888881683349609 14.99900054931641 C 2.53192138671875 15.61643981933594 2.53192138671875 16.38356018066406 2.888881683349609 17.00099945068359 L 9.826381683349609 29.00101089477539 C 10.18262100219727 29.61721038818359 10.84608268737793 30 11.55785179138184 30 L 25.44214248657227 30 C 26.15391159057617 30 26.8173713684082 29.61721038818359 27.17361068725586 29.00099945068359 L 34.11112213134766 17.00099945068359 C 34.46808242797852 16.38356018066406 34.46808242797852 15.61643981933594 34.11112213134766 14.99900054931641 L 27.17361068725586 2.998989105224609 C 26.8173713684082 2.382789611816406 26.15391159057617 2 25.44214248657227 2 L 11.55785179138184 2 M 11.55785179138184 0 L 25.44214248657227 0 C 26.87038230895996 0 28.19025230407715 0.7615203857421875 28.90508270263672 1.997989654541016 L 35.84259033203125 13.99798965454102 C 36.55867004394531 15.23661041259766 36.55867004394531 16.76338958740234 35.84259033203125 18.00201034545898 L 28.90508270263672 30.00201034545898 C 28.19025230407715 31.23847961425781 26.87038230895996 32 25.44214248657227 32 L 11.55785179138184 32 C 10.1296215057373 32 8.809741973876953 31.23847961425781 8.094911575317383 30.00201034545898 L 1.157413482666016 18.00201034545898 C 0.4413337707519531 16.76338005065918 0.4413337707519531 15.23661041259766 1.157413482666016 13.99798965454102 L 8.094911575317383 1.997989654541016 C 8.809751510620117 0.7615203857421875 10.1296215057373 0 11.55785179138184 0 Z"
              stroke="none"
              fill="#3DADAD"
            />
          </g>
          <g
            id="Polygon_6"
            data-name="Polygon 6"
            transform="translate(327.479 110.894) rotate(30)"
            fill="#f8f9fa"
          >
            <path
              d="M 25.44214248657227 31 L 11.55785179138184 31 C 10.49020195007324 31 9.495012283325195 30.42580986022949 8.960641860961914 29.50149917602539 L 2.023141860961914 17.50151062011719 C 1.487711906433105 16.57534027099609 1.487711906433105 15.42465019226074 2.023141860961914 14.49849033355713 L 8.960641860961914 2.49849009513855 C 9.495012283325195 1.574190020561218 10.49020195007324 1 11.55785179138184 1 L 25.44214248657227 1 C 26.50979232788086 1 27.50498199462891 1.574190020561218 28.03934097290039 2.49849009513855 L 34.97686004638672 14.49849033355713 C 35.51229095458984 15.42465972900391 35.51229095458984 16.57534980773926 34.97686004638672 17.50151062011719 L 28.03935241699219 29.50151062011719 C 27.50498199462891 30.42580986022949 26.50979232788086 31 25.44214248657227 31 Z"
              stroke="none"
            />
            <path
              d="M 11.55785179138184 2 C 10.84609222412109 2 10.18262100219727 2.38279914855957 9.826381683349609 2.999000549316406 L 2.888881683349609 14.99900054931641 C 2.53192138671875 15.61643981933594 2.53192138671875 16.38356018066406 2.888881683349609 17.00099945068359 L 9.826381683349609 29.00101089477539 C 10.18262100219727 29.61721038818359 10.84608268737793 30 11.55785179138184 30 L 25.44214248657227 30 C 26.15391159057617 30 26.8173713684082 29.61721038818359 27.17361068725586 29.00099945068359 L 34.11112213134766 17.00099945068359 C 34.46808242797852 16.38356018066406 34.46808242797852 15.61643981933594 34.11112213134766 14.99900054931641 L 27.17361068725586 2.998989105224609 C 26.8173713684082 2.382789611816406 26.15391159057617 2 25.44214248657227 2 L 11.55785179138184 2 M 11.55785179138184 0 L 25.44214248657227 0 C 26.87038230895996 0 28.19025230407715 0.7615203857421875 28.90508270263672 1.997989654541016 L 35.84259033203125 13.99798965454102 C 36.55867004394531 15.23661041259766 36.55867004394531 16.76338958740234 35.84259033203125 18.00201034545898 L 28.90508270263672 30.00201034545898 C 28.19025230407715 31.23847961425781 26.87038230895996 32 25.44214248657227 32 L 11.55785179138184 32 C 10.1296215057373 32 8.809741973876953 31.23847961425781 8.094911575317383 30.00201034545898 L 1.157413482666016 18.00201034545898 C 0.4413337707519531 16.76338005065918 0.4413337707519531 15.23661041259766 1.157413482666016 13.99798965454102 L 8.094911575317383 1.997989654541016 C 8.809751510620117 0.7615203857421875 10.1296215057373 0 11.55785179138184 0 Z"
              stroke="none"
              fill="#3DADAD"
            />
          </g>
          <g
            id="Polygon_7"
            data-name="Polygon 7"
            transform="translate(265.479 76.894) rotate(30)"
            fill="#f8f9fa"
          >
            <path
              d="M 25.44214248657227 31 L 11.55785179138184 31 C 10.49020195007324 31 9.495012283325195 30.42580986022949 8.960641860961914 29.50149917602539 L 2.023141860961914 17.50151062011719 C 1.487711906433105 16.57534027099609 1.487711906433105 15.42465019226074 2.023141860961914 14.49849033355713 L 8.960641860961914 2.49849009513855 C 9.495012283325195 1.574190020561218 10.49020195007324 1 11.55785179138184 1 L 25.44214248657227 1 C 26.50979232788086 1 27.50498199462891 1.574190020561218 28.03934097290039 2.49849009513855 L 34.97686004638672 14.49849033355713 C 35.51229095458984 15.42465972900391 35.51229095458984 16.57534980773926 34.97686004638672 17.50151062011719 L 28.03935241699219 29.50151062011719 C 27.50498199462891 30.42580986022949 26.50979232788086 31 25.44214248657227 31 Z"
              stroke="none"
            />
            <path
              d="M 11.55785179138184 2 C 10.84609222412109 2 10.18262100219727 2.38279914855957 9.826381683349609 2.999000549316406 L 2.888881683349609 14.99900054931641 C 2.53192138671875 15.61643981933594 2.53192138671875 16.38356018066406 2.888881683349609 17.00099945068359 L 9.826381683349609 29.00101089477539 C 10.18262100219727 29.61721038818359 10.84608268737793 30 11.55785179138184 30 L 25.44214248657227 30 C 26.15391159057617 30 26.8173713684082 29.61721038818359 27.17361068725586 29.00099945068359 L 34.11112213134766 17.00099945068359 C 34.46808242797852 16.38356018066406 34.46808242797852 15.61643981933594 34.11112213134766 14.99900054931641 L 27.17361068725586 2.998989105224609 C 26.8173713684082 2.382789611816406 26.15391159057617 2 25.44214248657227 2 L 11.55785179138184 2 M 11.55785179138184 0 L 25.44214248657227 0 C 26.87038230895996 0 28.19025230407715 0.7615203857421875 28.90508270263672 1.997989654541016 L 35.84259033203125 13.99798965454102 C 36.55867004394531 15.23661041259766 36.55867004394531 16.76338958740234 35.84259033203125 18.00201034545898 L 28.90508270263672 30.00201034545898 C 28.19025230407715 31.23847961425781 26.87038230895996 32 25.44214248657227 32 L 11.55785179138184 32 C 10.1296215057373 32 8.809741973876953 31.23847961425781 8.094911575317383 30.00201034545898 L 1.157413482666016 18.00201034545898 C 0.4413337707519531 16.76338005065918 0.4413337707519531 15.23661041259766 1.157413482666016 13.99798965454102 L 8.094911575317383 1.997989654541016 C 8.809751510620117 0.7615203857421875 10.1296215057373 0 11.55785179138184 0 Z"
              stroke="none"
              fill="#3DADAD"
            />
          </g>
          <g
            id="Polygon_5"
            data-name="Polygon 5"
            transform="translate(286.479 110.894) rotate(30)"
            fill="#f8f9fa"
          >
            <path
              d="M 25.44214248657227 31 L 11.55785179138184 31 C 10.49020195007324 31 9.495012283325195 30.42580986022949 8.960641860961914 29.50149917602539 L 2.023141860961914 17.50151062011719 C 1.487711906433105 16.57534027099609 1.487711906433105 15.42465019226074 2.023141860961914 14.49849033355713 L 8.960641860961914 2.49849009513855 C 9.495012283325195 1.574190020561218 10.49020195007324 1 11.55785179138184 1 L 25.44214248657227 1 C 26.50979232788086 1 27.50498199462891 1.574190020561218 28.03934097290039 2.49849009513855 L 34.97686004638672 14.49849033355713 C 35.51229095458984 15.42465972900391 35.51229095458984 16.57534980773926 34.97686004638672 17.50151062011719 L 28.03935241699219 29.50151062011719 C 27.50498199462891 30.42580986022949 26.50979232788086 31 25.44214248657227 31 Z"
              stroke="none"
            />
            <path
              d="M 11.55785179138184 2 C 10.84609222412109 2 10.18262100219727 2.38279914855957 9.826381683349609 2.999000549316406 L 2.888881683349609 14.99900054931641 C 2.53192138671875 15.61643981933594 2.53192138671875 16.38356018066406 2.888881683349609 17.00099945068359 L 9.826381683349609 29.00101089477539 C 10.18262100219727 29.61721038818359 10.84608268737793 30 11.55785179138184 30 L 25.44214248657227 30 C 26.15391159057617 30 26.8173713684082 29.61721038818359 27.17361068725586 29.00099945068359 L 34.11112213134766 17.00099945068359 C 34.46808242797852 16.38356018066406 34.46808242797852 15.61643981933594 34.11112213134766 14.99900054931641 L 27.17361068725586 2.998989105224609 C 26.8173713684082 2.382789611816406 26.15391159057617 2 25.44214248657227 2 L 11.55785179138184 2 M 11.55785179138184 0 L 25.44214248657227 0 C 26.87038230895996 0 28.19025230407715 0.7615203857421875 28.90508270263672 1.997989654541016 L 35.84259033203125 13.99798965454102 C 36.55867004394531 15.23661041259766 36.55867004394531 16.76338958740234 35.84259033203125 18.00201034545898 L 28.90508270263672 30.00201034545898 C 28.19025230407715 31.23847961425781 26.87038230895996 32 25.44214248657227 32 L 11.55785179138184 32 C 10.1296215057373 32 8.809741973876953 31.23847961425781 8.094911575317383 30.00201034545898 L 1.157413482666016 18.00201034545898 C 0.4413337707519531 16.76338005065918 0.4413337707519531 15.23661041259766 1.157413482666016 13.99798965454102 L 8.094911575317383 1.997989654541016 C 8.809751510620117 0.7615203857421875 10.1296215057373 0 11.55785179138184 0 Z"
              stroke="none"
              fill="#3DADAD"
            />
          </g>
        </g>
      </svg>
    </div>
  )
}
