import scrollTo from "gatsby-plugin-smoothscroll"
import React from "react"
import bg from "../../images/powr.svg"
import { colors } from "../../utils"
import "./hero.css"
import { Logo } from "./Logo"
import { navigate } from "gatsby"

function Buttton({ onClick, content = "Learn more" }) {
  return (
    <button
      style={{
        background: colors.green,
        borderRadius: "8px",
        boxShadow: "0px 2px 4px rgba(33, 37, 41, 0.08)",
        textAlign: "center",
        font: "500 16px/24px Poppins",
        letterSpacing: "0px",
        color: "#ffffff",
        border: "none",
        padding: "17px 48px",
        display: "flex",
        cursor: "pointer",
        marginTop: "80px",
      }}
      onClick={onClick}
    >
      {content}
    </button>
  )
}

export default function Hero() {
  return (
    <section className="hero-section hero-section-3">
      <div
        style={{
          background: "#F8F9FA",
          position: "absolute",
          left: "50%",
          width: "50%",
          height: "100%",
        }}
      />
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div
            className="col-xs-12"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Logo
              onClick={() => {
                console.log("click")
                navigate("/")
              }}
            />
            <div className="hero-content-left ptb-100">
              <h1
                style={{
                  color: colors.basic,
                  fontSize: 48,
                  marginBottom: 24,
                  textAlign: "center",
                }}
              >
                Meet out ambassadors
              </h1>
              <p
                style={{
                  color: colors.basic,
                  fontFamily: "Poppins",
                  fontSize: 16,
                  fontWeight: 400,
                  maxWidth: "750px",
                  textAlign: "center",
                }}
                className="lead"
              >
                Vestibulum commodo sapien non elit porttitor, vitae volutpat
                nibh mollis. Nulla porta risus id neque tempor, in efficitur
                justo imperdiet.
              </p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Buttton
                  content="See ambassadors"
                  onClick={() => scrollTo("#ambassadors")}
                />
              </div>
            </div>
          </div>
        </div>
        <img src={bg} className="shape-image" alt="shape" />
      </div>
    </section>
  )
}
