import React from "react"
import Layout from "../components/layout"
import { PasswordGuardContext } from "../components/password-guard.context"

import SEO from "../components/seo"
import Hero from "../components/ambasadors/hero"
import { colors } from "../utils"
import GatsbyImage from "gatsby-image"
import { useStaticQuery, navigate } from "gatsby"
import SectionTwelve from "../components/section-twelve/twelve"

const AmbasadorsPage = () => {
  const { isVerified } = React.useContext(PasswordGuardContext)
  const data = useStaticQuery(query)
  if (isVerified)
    return (
      <Layout>
        <SEO lang="en" title="Home" />
        <Hero />
        <section id="ambassadors" style={{ borderTop: "1px solid #DEE2E6" }}>
          <div className="container">
            <div className="row align-items-center">
              <div className="col col-xs-12">
                <h1
                  style={{
                    color: colors.basic,
                    fontSize: 48,
                    marginBottom: 24,
                    textAlign: "left",
                    marginTop: "80px",
                  }}
                >
                  Recovery
                </h1>
                <p
                  style={{
                    color: colors.basic,
                    fontFamily: "Poppins",
                    fontSize: 16,
                    fontWeight: 400,
                    maxWidth: "750px",
                    textAlign: "left",
                    marginBottom: "80px",
                  }}
                  className="lead"
                >
                  Fusce at nisi eget dolor rhoncus facilisis. Mauris ante nisl,
                  consectetur et luctus et, porta ut dolor. Curabitur ultricies
                  ultrices nulla. Morbi blandit nec est vitae dictum.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col col-xs-12 col-md-4">
                <div className="main-placeholder-ambassador">
                  <div className="mb-lg-0">
                    <div className="text-container">
                      <GatsbyImage
                        style={{ width: 364, height: 364 }}
                        className="img-fluid"
                        fluid={data.third.childImageSharp.fluid}
                        alt="animation"
                      />
                      <div>
                        <h5 className="title-ten">Ricky Lightfoot</h5>
                        <p className="content-ten mb-0">
                          World Trail Running Champion, Mountain Runner - Great
                          Britain & England
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col col-xs-12 col-md-4">
                <div className="main-placeholder-ambassador">
                  <div className="mb-lg-0">
                    <div className="text-container">
                      <GatsbyImage
                        style={{ width: 364, height: 364 }}
                        className="img-fluid"
                        fluid={data.first.childImageSharp.fluid}
                        alt="animation"
                      />
                      <div>
                        <h5 className="title-ten">Charlie Guest</h5>
                        <p className="content-ten mb-0">
                          Olympic Alpine Skier - Slalom & Parallel Slalom, 1st
                          British Woman To Ever Win A European Cup
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col col-xs-12 col-md-4">
                <div className="main-placeholder-ambassador">
                  <div className="mb-lg-0">
                    <div className="text-container">
                      <GatsbyImage
                        style={{ width: 364, height: 364 }}
                        className="img-fluid"
                        fluid={data.second.childImageSharp.fluid}
                        alt="animation"
                      />
                      <div>
                        <h5 className="title-ten">Pam Thorburn</h5>
                        <p className="content-ten mb-0">
                          British Champion Alpine & Ski Cross, British World Cup
                          Team Alpine & Ski Cross
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col col-xs-12 col-md-4">
                <div className="main-placeholder-ambassador">
                  <div className="mb-lg-0">
                    <div className="text-container">
                      <GatsbyImage
                        style={{ width: 364, height: 364 }}
                        className="img-fluid"
                        fluid={data.ellie.childImageSharp.fluid}
                        alt="animation"
                      />
                      <div>
                        <h5 className="title-ten">Ellie Dickinson</h5>
                        <p className="content-ten mb-0">
                          GB Road & Track Cyclist, European Champion, World
                          Championship Medal Winner
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section style={{ borderTop: "1px solid #DEE2E6" }}>
          <div className="container">
            <div className="row align-items-center">
              <div className="col col-xs-12">
                <h1
                  style={{
                    color: colors.basic,
                    fontSize: 48,
                    marginBottom: 24,
                    textAlign: "left",
                    marginTop: "80px",
                  }}
                >
                  Performance gains
                </h1>
                <p
                  style={{
                    color: colors.basic,
                    fontFamily: "Poppins",
                    fontSize: 16,
                    fontWeight: 400,
                    maxWidth: "750px",
                    textAlign: "left",
                    marginBottom: "80px",
                  }}
                  className="lead"
                >
                  Fusce at nisi eget dolor rhoncus facilisis. Mauris ante nisl,
                  consectetur et luctus et, porta ut dolor. Curabitur ultricies
                  ultrices nulla. Morbi blandit nec est vitae dictum.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col col-xs-12 col-md-4">
                <div className="main-placeholder-ambassador">
                  <div className="mb-lg-0">
                    <div className="text-container">
                      <GatsbyImage
                        style={{ width: 364, height: 364 }}
                        className="img-fluid"
                        fluid={data.lamin.childImageSharp.fluid}
                        alt="animation"
                      />
                      <div>
                        <h5 className="title-ten">Lamin Deen</h5>
                        <p className="content-ten mb-0">
                          Double Olympian, World Cup Medalist - GB Bobsleigh
                          Driver
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col col-xs-12 col-md-4">
                <div className="main-placeholder-ambassador">
                  <div className="mb-lg-0">
                    <div className="text-container">
                      <GatsbyImage
                        style={{ width: 364, height: 364 }}
                        className="img-fluid"
                        fluid={data.ryan.childImageSharp.fluid}
                        alt="animation"
                      />
                      <div>
                        <h5 className="title-ten">Ryan Letts</h5>
                        <p className="content-ten mb-0">
                          GB Bobsleigh Team Member
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col col-xs-12 col-md-4">
                <div className="main-placeholder-ambassador">
                  <div className="mb-lg-0">
                    <div className="text-container">
                      <GatsbyImage
                        style={{ width: 364, height: 364 }}
                        className="img-fluid"
                        fluid={data.ben2.childImageSharp.fluid}
                        alt="animation"
                      />
                      <div>
                        <h5 className="title-ten">Ben Simons</h5>
                        <p className="content-ten mb-0">
                          Double Olympian - GB Bobsleigh Team Member
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col col-xs-12 col-md-4">
                <div className="main-placeholder-ambassador">
                  <div className="mb-lg-0">
                    <div className="text-container">
                      <GatsbyImage
                        style={{ width: 364, height: 364 }}
                        className="img-fluid"
                        fluid={data.olly.childImageSharp.fluid}
                        alt="animation"
                      />
                      <div>
                        <h5 className="title-ten">Olly Butterworth</h5>
                        <p className="content-ten mb-0">
                          GB Bobsleigh Team Member, Former Rugby Player & 400m
                          Runner
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col col-xs-12 col-md-4">
                <div className="main-placeholder-ambassador">
                  <div className="mb-lg-0">
                    <div className="text-container">
                      <GatsbyImage
                        style={{ width: 364, height: 364 }}
                        className="img-fluid"
                        fluid={data.ieuan.childImageSharp.fluid}
                        alt="animation"
                      />
                      <div>
                        <h5 className="title-ten">Ieuan Evans MBE</h5>
                        <p className="content-ten mb-0">
                          Former Wales And British And Irish Lions International
                          Rugby Player
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section style={{ borderTop: "1px solid #DEE2E6" }}>
          <div className="container">
            <div className="row align-items-center">
              <div className="col col-xs-12">
                <h1
                  style={{
                    color: colors.basic,
                    fontSize: 48,
                    marginBottom: 24,
                    textAlign: "left",
                    marginTop: "80px",
                  }}
                >
                  Nisl sodales libero
                </h1>
                <p
                  style={{
                    color: colors.basic,
                    fontFamily: "Poppins",
                    fontSize: 16,
                    fontWeight: 400,
                    maxWidth: "750px",
                    textAlign: "left",
                    marginBottom: "80px",
                  }}
                  className="lead"
                >
                  Fusce at nisi eget dolor rhoncus facilisis. Mauris ante nisl,
                  consectetur et luctus et, porta ut dolor. Curabitur ultricies
                  ultrices nulla. Morbi blandit nec est vitae dictum.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col col-xs-12 col-md-4">
                <div className="main-placeholder-ambassador">
                  <div className="mb-lg-0">
                    <div className="text-container">
                      <GatsbyImage
                        style={{ width: 364, height: 364 }}
                        className="img-fluid"
                        fluid={data.madeleine.childImageSharp.fluid}
                        alt="animation"
                      />
                      <div>
                        <h5 className="title-ten">Madeleine Bell</h5>
                        <p className="content-ten mb-0">
                          Professional Ballet Dancer
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col col-xs-12 col-md-4">
                <div className="main-placeholder-ambassador">
                  <div className="mb-lg-0">
                    <div className="text-container">
                      <GatsbyImage
                        style={{ width: 364, height: 364 }}
                        className="img-fluid"
                        fluid={data.hege.childImageSharp.fluid}
                        alt="animation"
                      />
                      <div>
                        <h5 className="title-ten">Hege Henriksen</h5>
                        <p className="content-ten mb-0">
                          Professional OCR Athlete, Winner 24 Hour Kvalik Race
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col col-xs-12 col-md-4">
                <div className="main-placeholder-ambassador">
                  <div className="mb-lg-0">
                    <div className="text-container">
                      <GatsbyImage
                        style={{ width: 364, height: 364 }}
                        className="img-fluid"
                        fluid={data.jaqui.childImageSharp.fluid}
                        alt="animation"
                      />
                      <div>
                        <h5 className="title-ten">Jaqui Slack</h5>
                        <p className="content-ten mb-0">
                          British, European & World Cup Triathlete
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <SectionTwelve />
      </Layout>
    )

  return null
}

export default AmbasadorsPage

export const query = graphql`
  query AmbassadorsQuery {
    first: file(relativePath: { eq: "Charlie.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    second: file(relativePath: { eq: "Pam.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    third: file(relativePath: { eq: "Ricky.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    ellie: file(relativePath: { eq: "Ellie.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    lamin: file(relativePath: { eq: "Lamin.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    ryan: file(relativePath: { eq: "Ryan.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    ben2: file(relativePath: { eq: "Ben2.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    olly: file(relativePath: { eq: "Olly.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    ieuan: file(relativePath: { eq: "Ieuan.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    madeleine: file(relativePath: { eq: "Madeleine.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    hege: file(relativePath: { eq: "Hege.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    jaqui: file(relativePath: { eq: "Jaqui.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`
